const config = {
  ENVIRONMENT_NAME: 'staging',
  forwoodId: {
    URL: 'https://id.staging.ozminerals.forwoodsafety.com',
    APP_CLIENT_ID: 'ub2f7aki9aumk9v4jeq0a6a16',
    USER_TOKEN_URL: 'https://ylidoden84.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'ap-southeast-2',
    URL: 'https://ez1jgcf01c.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.9.1',
    HOSTNAME: 'https://admin.staging.ozminerals.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.staging.ozminerals.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.staging.ozminerals.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.ozminerals.forwoodsafety.com',
    WEBSOCKET: '/staging'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;